<template>
  <div>
    <v-data-table
      :headers="cabecalhoPorTipoContrato()"
      :items="tipoContrato.contratos"
      :options.sync="pagination"
      :server-items-length="totalPage"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 20],
      }">
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.nome">
            <td style="width: 2%; text-align: center;">
              <v-menu bottom
                v-if="contratoCancelado(item)"
                origin="center center"
                transition="scale-transition">
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    :id="'more_vert_' + item.id"
                    icon>
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    @click="detalharContrato(item.id)">
                    <v-list-item-action>
                      <v-icon>details</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.detalhe', 2) }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu bottom
                v-else
                origin="center center"
                transition="scale-transition">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on"
                    icon
                    text>
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-if="!canEdit || !contratoEmCadastro(item)"
                    @click="detalharContrato(item.id)">
                    <v-list-item-action>
                      <v-icon>details</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.detalhe', 2) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="contratoEmCadastro(item) && canEdit"
                    @click="editarContrato(item.id)">
                    <v-list-item-action>
                      <v-icon>edit</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.editar', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-show="item.aprovadorFluxo && item.ind_possui_fluxo_aprovacao"
                    @click="abrirJustificativaAprovar(item)">
                    <v-list-item-action>
                      <v-icon>thumb_up</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.aprovar', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-show="item.aprovadorFluxo && item.ind_possui_fluxo_aprovacao"
                    @click="abrirJustificativaAnalise(item)">
                    <v-list-item-action>
                      <v-icon>chat_bubble</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.enviar_analise', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-show="(item.aprovadorFluxo && item.ind_possui_fluxo_aprovacao) && exibicaoReprovarPersonalizada(item)"
                    @click="abrirJustificativaReprovar(item)">
                    <v-list-item-action>
                      <v-icon>thumb_down</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.reprovar', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-show="item.ind_possui_fluxo_aprovacao &&
                     (item.status === 'REPROVADO' || item.status === 'EM_ANALISE') &&
                     item.tem_fluxo && !item.tem_fluxo_aprovado"
                    @click="abrirJustificativa(item)">
                    <v-list-item-action>
                      <v-icon>textsms</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.justificativa', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-show="item.ind_possui_fluxo_aprovacao &&
                     (item.status === 'EM_ANALISE' && canEdit && item.solicitanteFluxo) &&
                     !item.tem_fluxo_aprovado"
                    @click="confirmarExecutarAnalise(item)">
                    <v-list-item-action>
                      <v-icon>edit</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.executar_analise', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="mostrarAcompanhamento(item) && temFluxo(item)"
                    @click="abrirAcompanhamento(item)">
                    <v-list-item-action>
                      <v-icon>assignment_turned_in</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.acompanhamento', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="exibeCancelarContrato(item) && exibicaoCancelarPersonalizada(item)"
                    @click="confirmarCancelarContrato(item)">
                    <v-list-item-action>
                      <v-icon>cancel</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.cancelar', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-for="(botao) in botoesCustomizados"
                               :id="`${item.id}-botao-${botao.id}`"
                               :key="`${item.id}-botao-${botao.id}`"
                      v-if="botao.exibe(item)"
                      @click="botao.click(item)">
                    <v-list-item-action>
                      <v-icon>{{ botao.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ botao.label }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
            <td v-for="col in colunasPorTipoContrato()" :key="col">
              <status
                v-if="col === 'status'"
                :status="item.status"
                :tooltip="item.status">
              </status>
              <span v-else-if="col === 'divisao'">
                <v-menu open-on-hover
                  offset-x
                  top
                  :nudge-width="300"
                  :close-on-content-click="false">
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      {{item.divisao}}
                    </span>
                  </template>
                  <v-card class="PlanejamentoContratoListTabela__Hier">
                    <v-card-title>
                      {{$tc('label.hierarquia', 1)}}
                    </v-card-title>
                    <v-card-text>
                      <v-treeview
                        open-all
                        open-on-click
                        :items="item.treeDivisao"
                        />
                    </v-card-text>
                  </v-card>
                </v-menu>
              </span>
            <span v-else-if="col === 'contratosacoespend' && item.contratosacoespend === 'Sim'">
                <v-menu open-on-hover
                        offset-x
                        top
                        :nudge-width="300"
                        :close-on-content-click="false">
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                       <v-icon color="silver">warning</v-icon>
                    </span>
                  </template>
                  <v-card class="PlanejamentoContratoListTabela__Hier">
                    <v-card-title>
                      {{'Ações Pendentes:'}}
                    </v-card-title>
                    <v-card-text>
                      <v-treeview
                        open-all
                        open-on-click
                      <v-data-table
                        :headers="cabecalhoAcoesPend"
                        :footer-props="{itemsPerPageOptions: [5],} "
                        :items="item.treeAcoespend"
                        :no-data-text="$t('label.tabela_sem_conteudo')">
                          <template v-slot:item.nome_acao="{ item }">
                             {{ item.nome_acao }}
                          </template>
                          <template v-slot:item.tipo_acao="{ item }">
                             {{ item.tipo_acao }}
                          </template>
                          <template v-slot:item.status_acao="{ item }">
                             <status
                               :status="item.status_acao"
                               :tooltip="item.status_acao">
                             </status>
                          </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </span>
              <span v-else>
                {{formataValorColuna(col, item)}}
              </span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <planejamento-acao-justificativa
      ref="modalJustificativa"
      :obrigatorio="justificativaObrigatoria"
      :salvarJustificativa="actionJustificativa"
      :observacao="justificativa"
      :somenteLeitura="justificativaSomenteLeitura"
    >
    </planejamento-acao-justificativa>

    <confirm
      ref="confirmDialog"
      :persistent="true">
    </confirm>

    <acompanhamento
      ref="modalAcompanhamento"
      :fluxo="statusPassos"
    >
    </acompanhamento>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import PlanejamentoAcaoJustificativa from '@/spa/planejamento-acao/PlanejamentoAcaoJustificativa';
import BotoesDadosTabelaMixin from '@/spa/planejamento-contrato/list/BotoesDadosTabelaMixin';
import { generateComputed } from '../../../common/functions/roles-computed-generator';
import { buscarContratos } from '../../../common/resources/planejamento/planejamento-contrato-listagem';
import { getMoney } from '../../../common/functions/helpers';
import Status from '../../../shared-components/Status';
import Confirm from '../../../shared-components/vuetify/dialog/Confirm';
import Acompanhamento from '../../../shared-components/workflow/Acompanhamento';
import { skipLoading } from '../../../common/functions/loading';

export default {
  components: {
    Status,
    Confirm,
    PlanejamentoAcaoJustificativa,
    Acompanhamento,
  },
  mixins: [BotoesDadosTabelaMixin],
  computed: {
    ...mapGetters('metadados', [
      'getContratoMetadado',
    ]),
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    ...generateComputed('CONTRATO', [
      'canEdit',
    ]),
    canCancelarContrato() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'CANCELAR_CONTRATO').length;
    },
    metadadosContrato() {
      if (this.getContratoMetadado && this.getContratoMetadado.mapaEntidades) {
        return this.getContratoMetadado;
      }
      return undefined;
    },
  },
  props: {
    tipoContrato: Object,
    ordenacao: Array,
    filtros: Object,
    agrupamento: String,
    labelCampoCliente: {
      type: String,
      default: '',
    },
  },
  watch: {
    pagination: {
      handler(pagination) {
        if (this.mesmaPagina(pagination, this.lastPagination)) {
          return;
        }
        this.lastPagination = pagination;
        this.buscarContratos();
      },
      deep: true,
    },
  },
  data() {
    return {
      workflowContratoResource: this.$api.workflowContrato(this.$resource),
      planejamentoContratoResource: this.$api.planejamentoContratoCadastro(this.$resource),
      listaAcoesPendentes: [],
      pagination: {
        rowsPerPage: 10,
      },
      totalPage: 0,
      lastPagination: {},
      cabecalhoAcoesPend: [
        {
          text: 'Descrição da Ação',
          value: 'nome_acao',
          sortable: false,
          class: 'PlanejamentoContratoListTabela__ColunaGrande',
        },
        {
          text: 'Tipo da Ação',
          value: 'tipo_acao',
          sortable: false,
        },
        {
          text: this.$tc('label.status', 1),
          value: 'status_acao',
          sortable: false,
          class: 'PlanejamentoContratoListTabela__ColunaGrande',
        },
      ],
      cabecalhoFixo: [
        {
          text: this.$tc('label.descricao', 1),
          value: 'descricao',
          sortable: false,
          class: 'PlanejamentoContratoListTabela__ColunaGrande',
        },
        {
          text: 'Ações Pendentes',
          value: 'contratosacoespend',
          sortable: false,
        },
        {
          text: this.$tc('label.periodo', 1),
          value: 'periodo',
          sortable: false,
          class: 'PlanejamentoContratoListTabela__ColunaGrande',
        },
        { text: this.$tc('label.planejado', 1), value: 'planejado', sortable: false },
        { text: this.$tc('label.comprometido', 1), value: 'comprometido', sortable: false },
        { text: this.$tc('label.consumido', 1), value: 'consumido', sortable: false },
        { text: this.$tc('label.reservado', 1), value: 'reservado', sortable: false },
        { text: this.$tc('label.adendo', 1), value: 'adendo', sortable: false },
        { text: this.$tc('label.reducao', 1), value: 'reducao', sortable: false },
        { text: this.$tc('label.remanescente', 1), value: 'remanescente', sortable: false },
        { text: this.$tc('label.apurado', 1), value: 'apurado', sortable: false },
        { text: this.$tc('label.data_inicio', 1), value: 'data_inicio', sortable: false },
        { text: this.$tc('label.data_fim', 1), value: 'data_fim', sortable: false },
        { text: this.$tc('label.saldo', 1), value: 'saldo', sortable: false },
        { text: this.$tc('label.valor_consumido', 1), value: 'valor_consumido', sortable: false },
        { text: this.$tc('label.periodo_apuracao', 1), value: 'periodo_apuracao', sortable: false },
        { text: this.$tc('label.fornecedor', 1), value: 'fornecedor', sortable: false },
        { text: this.$tc('label.campo_cliente', 1), value: 'campo_cliente', sortable: false },
        { text: this.$tc('label.cliente', 1), value: 'cliente', sortable: false },
        { text: this.$tc('label.holding', 1), value: 'holding', sortable: false },
        { text: this.$tc('label.produto', 1), value: 'produto', sortable: false },
        { text: this.$tc('label.tipo_pagamento', 1), value: 'tipo_pagamento', sortable: false },
        {
          text: this.$tc('label.acoes', 2), value: 'acoes', sortable: false, width: '2%', align: 'center',
        },
        { text: this.$tc('label.unidade_negocio', 1), value: 'unidadenegocio', sortable: false },
        { text: this.$tc('label.grupo_fornecedor', 1), value: 'grupo_fornecedor', sortable: false },
        { text: this.$tc('label.divisao', 1), value: 'divisao', sortable: false },
        { text: this.$tc('label.periodo_planejamento', 1), value: 'periodo_planejamento', sortable: false },
        { text: this.$tc('label.calculo_apuracao', 1), value: 'calculo_apuracao', sortable: false },
        { text: this.$tc('label.base_calculo', 1), value: 'base_calculo', sortable: false },
        { text: this.$tc('label.forma_bonificacao', 1), value: 'forma_bonificacao', sortable: false },
        { text: this.$tc('label.calculo_bonificacao', 1), value: 'calculo_bonificacao', sortable: false },
        {
          text: this.$tc('label.valor', 1),
          value: 'valor',
          sortable: false,
          class: 'PlanejamentoContratoListTabela__ColunaMedia',
        },
        { text: this.$tc('label.status', 1), value: 'status', sortable: false },
      ],

      justificativaObrigatoria: false,
      actionJustificativa() {},
      contratoSelecionada: {},
      justificativa: '',
      justificativaSomenteLeitura: false,
      statusPassos: {},
    };
  },
  methods: {
    getMoney,
    mesmaPagina(p1, p2) {
      return p1.page === p2.page && p1.itemsPerPage === p2.itemsPerPage;
    },
    buscarContratos() {
      const params = {
        ...this.filtros,
        numeroPagina: this.pagination.page,
        tamanhoPagina: this.pagination.itemsPerPage,
      };

      params.id_contrato_tipo = this.tipoContrato.id;

      if (this.tipoContrato.idAgrupamento) {
        params.id_agrupamento = this.tipoContrato.idAgrupamento;
        params.agrupamento = this.agrupamento;
      }

      buscarContratos(params, this.$resource)
        .then((res) => {
          this.totalPage = res.data.quantidadeRegistrosPagina;
          this.tipoContrato.contratos = [...res.data.resposta];
          this.tipoContrato.contratos.forEach((c) => {
            this.verificarUsuarioAprovador(c);
            this.verificarUsuarioSolicitante(c);
            this.buscarStatusFluxo(c);
            this.montaArvoreInvertidaDivisao(c);
            this.montaArvoreInvertidaAcoes(c);
          });
          setTimeout(() => this.$forceUpdate());
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    montaArvoreInvertidaDivisao(contrato) {
      const { hier } = contrato;
      if (!hier || hier.length === 0) {
        return;
      }

      const itemHier = (name, children) => ({ name, children });

      const converteArvore = (children) => {
        children = children.map((c) => {
          const item = itemHier(c.nome, converteArvore(c.filhos));
          if (item.children.length === 0) {
            item.disabled = true;
          }
          return item;
        });
        return children;
      };

      const tree = hier.map((h) => {
        const base = itemHier(h.nome, h.filhos);
        base.children = converteArvore(base.children);
        return base;
      });

      contrato.treeDivisao = tree;
    },
    montaArvoreInvertidaAcoes(contrato) {
      const { acoespend } = contrato;
      if (!acoespend || acoespend.value === null) {
        return;
      }

      contrato.treeAcoespend = JSON.parse(acoespend.value);
    },
    contratoAprovado(contrato) {
      const { status } = contrato;
      return status === 'APROVADO';
    },
    contratoReprovado(contrato) {
      const { status } = contrato;
      return status === 'REPROVADO';
    },
    contratoEmCadastro(contrato) {
      const { status } = contrato;
      return status === 'EM_CADASTRO';
    },
    contratoAguardandoAprovacao(contrato) {
      const { status } = contrato;
      return status === 'AGUARDANDO_APROVACAO';
    },
    contratoEmAnalise(contrato) {
      const { status } = contrato;
      return status === 'EM_ANALISE';
    },
    contratoCancelado(contrato) {
      const { status } = contrato;
      return status === 'CANCELADO';
    },
    editarContrato(idContrato) {
      this.$router.push({ name: 'editarContrato', params: { idContrato } });
    },
    detalharContrato(idContrato) {
      this.$router.push({ name: 'detalharContrato', params: { idContrato, from: 'planejamento-contrato' } });
    },
    cabecalhoPorTipoContrato() {
      const { tipoContrato } = this;
      if (tipoContrato.cabecalho) return tipoContrato.cabecalho;

      const ordenacao = this.colunasPorTipoContrato(),
        cabecalho = [];

      ordenacao.forEach((col) => {
        let isColFixa = false;
        for (let i = 0; i < this.cabecalhoFixo.length; i += 1) {
          const defCol = this.cabecalhoFixo[i];
          isColFixa = defCol.value === col;
          if (isColFixa) {
            if (defCol.value === 'campo_cliente') {
              defCol.text = `${this.$tc('label.foco_contrato', 1)} - ${this.labelCampoCliente}`;
            }
            cabecalho.push(defCol);
            break;
          }
        }
        if (!isColFixa) {
          cabecalho.push({
            text: this.tituloCampoDinamico(col),
            value: col,
            sortable: false,
          });
        }
      }, this);

      const colAcoes = this.cabecalhoFixo.filter((c) => c.value === 'acoes')[0];
      tipoContrato.cabecalho = [colAcoes, ...cabecalho];

      return tipoContrato.cabecalho;
    },
    colunasPorTipoContrato() {
      return this.ordenacao;
    },
    tituloCampoDinamico(nomeCampo) {
      const dependencia = this.getDepenciaMetadadosPorNome(nomeCampo);
      if (dependencia != null) {
        return dependencia.entidadeEstrangeira;
      }
      const campoDinamico = this.getCampoDinamicoPorNome(nomeCampo);
      if (!campoDinamico) {
        $logger.log(nomeCampo);
      }
      return campoDinamico.nomCampo;
    },
    formataValorColuna(nomeColuna, item) {
      const coluna = this.cabecalhoFixo.filter((c) => c.value === nomeColuna)[0],
        colunaFixa = coluna != null;

      if (colunaFixa) {
        const chave = coluna.exibicao || coluna.value;
        return this.ehCampoDinheiro(chave)
          ? this.getMoney(item[chave])
          : item[chave];
      }

      if (this.ehDependenciaExtensao(nomeColuna)) {
        return this.formataValorExtensao(nomeColuna, item);
      }

      // neste caso é um campo dinamico
      // TODO: implementar tratativa de formatador(?)
      return item[nomeColuna];
    },
    ehCampoDinheiro(coluna) {
      const camposDinheiro = ['valor_consumido', 'saldo', 'valor'];
      return camposDinheiro.indexOf(coluna) >= 0;
    },
    formataValorExtensao(coluna, item) {
      const dependencia = this.getDepenciaMetadadosPorNome(coluna),
        valorConcatenado = item[coluna];

      if (!valorConcatenado) return '';

      if (dependencia.isManyToMany) {
        return valorConcatenado.split(',')
          .map((p) => this.extraiNomeExtensao(p))
          .join(', ');
      }

      return this.extraiNomeExtensao(valorConcatenado);
    },
    extraiNomeExtensao(valorConcatenado) {
      const valores = valorConcatenado.split('|'),
        nomExtensao = valores[valores.length - 1];
      return nomExtensao;
    },
    ehDependenciaExtensao(nomeCampo) {
      return this.getDepenciaMetadadosPorNome(nomeCampo) != null;
    },
    getDepenciaMetadadosPorNome(nomeCampo) {
      const dependencias = this.metadadosContrato.mapaEntidades,
        dependencia = dependencias[nomeCampo];
      return dependencia;
    },
    getCampoDinamicoPorNome(nomeCampo) {
      const campos = this.metadadosContrato.mapaCamposDinamicos,
        campo = campos[nomeCampo];
      return campo;
    },
    abrirJustificativaAprovar(item) {
      this.justificativaObrigatoria = false;
      this.actionJustificativa = this.aprovar;
      this.justificativa = '';
      this.justificativaSomenteLeitura = false;
      this.contratoSelecionado = item;
      setTimeout(() => this.$refs.modalJustificativa.open());
    },
    aprovar(justificativa) {
      const idContrato = this.contratoSelecionado.id;
      this.workflowContratoResource.aprovarPasso({ idContrato },
        { observacao: justificativa })
        .then(() => {
          this.$emit('refazer_busca');
          this.$toast(this.$t('message.contrato_aprovado'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    abrirJustificativaReprovar(item) {
      this.justificativaObrigatoria = true;
      this.actionJustificativa = this.reprovar;
      this.justificativa = '';
      this.justificativaSomenteLeitura = false;
      this.contratoSelecionado = item;
      setTimeout(() => this.$refs.modalJustificativa.open());
    },
    reprovar(justificativa) {
      const idContrato = this.contratoSelecionado.id;
      this.workflowContratoResource.reprovar({ idContrato },
        { observacao: justificativa })
        .then(() => {
          this.contratoSelecionado.status = 'REPROVADO';
          this.buscarStatusFluxo(this.contratoSelecionado);
          this.contratoSelecionado.aprovadorFluxo = false;
          this.$toast(this.$t('message.contrato_reprovada'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    confirmarCancelarContrato(contrato) {
      const content = this.$t('message.deseja_cancelar_contrato');
      setTimeout(() => this.$refs.confirmDialog
        .openWithParams(
          this.$t('title.atencao'),
          content,
          (ok) => {
            if (ok) {
              this.cancelarContrato(contrato);
            }
          },
        ));
    },
    cancelarContrato(contrato) {
      if (this.contratoEmCadastro(contrato)) {
        this.cancelarContratoEmCadastro(contrato);
      } else {
        this.cancelarContratoComFluxo(contrato);
      }
    },
    cancelarContratoEmCadastro(contrato) {
      const idContrato = contrato.id;
      this.planejamentoContratoResource.cancelarContrato({ idContrato }, { idContrato })
        .then(() => {
          this.buscarContratos();
          this.$toast(this.$t('message.contrato_cancelado'));
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    cancelarContratoComFluxo(contrato) {
      const idContrato = contrato.id;
      this.workflowContratoResource.cancelar({ idContrato }, { observacao: 'CANCELADO' })
        .then(() => {
          this.buscarContratos();
          if (contrato.tem_fluxo_aprovado) {
            this.$toast(this.$t('message.contrato_cancelado'));
          } else {
            this.$toast(this.$t('message.fluxo_cancelado_sucesso'));
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    abrirJustificativaAnalise(item) {
      this.justificativaObrigatoria = true;
      this.actionJustificativa = this.enviarAnalise;
      this.justificativa = '';
      this.justificativaSomenteLeitura = false;
      this.contratoSelecionado = item;
      setTimeout(() => this.$refs.modalJustificativa.open());
    },
    enviarAnalise(justificativa) {
      const idContrato = this.contratoSelecionado.id;
      this.workflowContratoResource.enviarAnalise({ idContrato },
        { observacao: justificativa })
        .then(() => {
          this.contratoSelecionado.status = 'EM_ANALISE';
          this.buscarStatusFluxo(this.contratoSelecionado);
          this.contratoSelecionado.aprovadorFluxo = false;
          this.$toast(this.$t('message.contrato_enviado_analise'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    verificarUsuarioAprovador(contrato) {
      if (contrato.status === 'AGUARDANDO_APROVACAO') {
        const idContrato = contrato.id;
        this.workflowContratoResource.aprovador({ idContrato })
          .then((response) => {
            if (response.data.usuarioHabilitado) {
              contrato.aprovadorFluxo = true;
              this.$forceUpdate();
            }
          });
      }
    },
    verificarUsuarioSolicitante(contrato) {
      if (contrato.status === 'EM_CADASTRO'
          || contrato.status === 'AGUARDANDO_APROVACAO'
          || contrato.status === 'EM_ANALISE'
          || contrato.status === 'REPROVADO'
          || this.permiteCancelarContrato(contrato)) {
        const idContrato = contrato.id;
        skipLoading();
        this.workflowContratoResource.solicitante({ idContrato })
          .then((response) => {
            contrato.solicitanteFluxo = response.data.usuarioHabilitado;
            this.$forceUpdate();
          });
      }
    },
    buscarStatusFluxo(contrato) {
      if ((contrato.status === 'REPROVADO' || contrato.status === 'EM_ANALISE')
        && contrato.ind_possui_fluxo_aprovacao && contrato.tem_fluxo
        && !contrato.tem_fluxo_aprovado) {
        const idContrato = contrato.id;
        this.workflowContratoResource.status({ idContrato })
          .then((response) => {
            contrato.fluxo = response.data;
          })
          .catch((err) => {
            this.$error(this, err);
          });
      }
    },
    abrirJustificativa(contrato) {
      this.justificativa = contrato.fluxo.passoAtual.observacao;
      this.justificativaSomenteLeitura = true;
      setTimeout(() => this.$refs.modalJustificativa.open());
    },
    confirmarExecutarAnalise(contrato) {
      const content = this.$t('message.deseja_executar_analise_contrato');
      setTimeout(() => this.$refs.confirmDialog
        .openWithParams(
          this.$t('title.atencao'),
          content,
          (ok) => {
            if (ok) {
              this.executarAnalise(contrato);
            }
          },
        ));
    },
    executarAnalise(contrato) {
      const idContrato = contrato.id;
      this.planejamentoContratoResource.executarAnalise({ idContrato }, { idContrato })
        .then(() => {
          this.$router.push({ name: 'editarContrato', params: { idContrato } });
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    mostrarAcompanhamento(item) {
      return item.status !== 'EM_CADASTRO' && item.status !== 'CANCELADO';
    },
    temFluxo(item) {
      return item.ind_possui_fluxo_aprovacao && item.tem_fluxo;
    },
    abrirAcompanhamento(item) {
      const idContrato = item.id;
      this.workflowContratoResource
        .existeFluxoCadastrado({ idContrato })
        .then((res) => {
          const existeFluxo = res.data;
          if (!existeFluxo) {
            const msg = this.$t('message.contrato_finalizado_sem_fluxo');
            this.$toast(msg);
            return;
          }
          this.exibeModalAcompanhamento(idContrato);
        });
    },
    exibeModalAcompanhamento(idContrato) {
      this.workflowContratoResource.statusPasso({ idContrato }, { idContrato })
        .then((res) => {
          this.statusPassos = res.data;
          setTimeout(() => this.$refs.modalAcompanhamento.open());
        })
        .catch((err) => {
          this.$toast(this.$t(err));
        });
    },
    exibeCancelarContrato(item) {
      return this.canEdit
            && (
              this.contratoEmCadastro(item)
              || (this.podeCancelarAgAprovacao(item))
              || (this.podeCancelarEmAnalise(item))
              || (this.permiteCancelarContrato(item))
            );
    },
    podeCancelarAgAprovacao(item) {
      return this.contratoAguardandoAprovacao(item)
        && (item.solicitanteFluxo || this.canCancelarContrato)
        && !item.tem_fluxo_aprovado;
    },
    podeCancelarEmAnalise(item) {
      return this.contratoEmAnalise(item)
        && (item.solicitanteFluxo || this.canCancelarContrato)
        && !item.tem_fluxo_aprovado;
    },
    permiteCancelarContrato(contrato) {
      return this.canCancelarContrato && contrato.permite_cancelar_contrato;
    },
  },
};
</script>
<style>
.PlanejamentoContratoListTabela__ColunaGrande {
  min-width: 210px;
}
.PlanejamentoContratoListTabela__ColunaMedia {
  min-width: 120px;
}
</style>
